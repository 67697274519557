$( document ).ready(function() {

    let _displayalert = $('#displayalert');
    let _displayalert_session = Cookie._getCookie('displayalert');

    let _datedump = '';
    if ( $('.displayalert-item').length > 0 ) {
        $('.displayalert-item').each(function () {
            _datedump += $(this).attr('data-time');
        });
    }

    if ( _datedump == _displayalert_session ) {
        // console.log('Same data time:');
        // console.log(_datedump);
        // console.log(_displayalert_session);
    }
    else {
        // console.log('Difference data time:');
        // console.log(_datedump);
        // console.log(_displayalert_session);
        Cookie._deleteCookie('displayalert');
    }

    if ( _displayalert_session ) {

        _displayalert.remove();
        // $('body').removeClass('show--displayalert');
        // _displayalert.attr('aria-hidden', true);
        $(window).trigger('resize');

    }
    else {

        if ( _displayalert.length > 0 ) {

            $('body').addClass('show--displayalert');
            _displayalert.attr('aria-hidden', false);

        }

        let _displayalert_carousel = $('.js-displayalert-carousel');
        try {
            _displayalert_carousel.not('.slick-initialized').slick({
                lazyLoad: "ondemand",
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                autoplay: true,
                autoplaySpeed: 8000,
                appendArrows: _displayalert_carousel.closest('.displayalert').find('.displayalert-control .slick-arrows-wrapper'),
                appendDots: _displayalert_carousel.closest('.displayalert').find('.displayalert-control .slick-dots-wrapper'),
                prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Go to previous slide</span></button>',
                nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Go to next slide</span></button>'
            });
        }
        catch (error) {
            console.log(error);
        }

        $(document).on('click', '#displayalert .button-play', function(e) {
            e.preventDefault();
            var _playButton = $(this);
            _playButton.toggleClass('play');
            if ( _playButton.hasClass('play') ) {
                _displayalert_carousel.slick('slickPlay');
            }
            else {
                _displayalert_carousel.slick('slickPause');
            }
        });

        $(document).on('click', '#displayalert .displayalert-close', function (e) {
            e.preventDefault();
            $('.displayalert').fadeOut('fast', function () {
                $('body').removeClass('show--displayalert');
                $('.displayalert').remove();
                Cookie._setCookie('displayalert', _datedump, 365);
                setTimeout(() => {
                    $(window).trigger('resize');
                }, 2);
            });
        });

        $(document).on('click', '.mm-blocker, .searchtoggle', function () {
            setTimeout(() => {
                _displayalert_carousel.slick('setPosition');
            }, 2);
        });
        
        $(window).trigger('resize');

    }

});

